import { useState, useMemo } from "react";
import groupBy from "lodash.groupby";
import { DropdownSwitcherBase } from "./components/DropdownSwitcherBase";
import { useTranslation } from "~/utils";
import type { ReactElement } from "react";

export interface Item {
  id: string;
  name: string;
  groupName?: string;
}

type GroupKeyFunction = (item: Item) => string;

export type DropdownSwitcherProps = {
  items: Item[];
  onSelect: (item: Item) => void;
  selectedItem?: Item;
  trigger?: ReactElement;
  groupByKey?: string | GroupKeyFunction;
  label?: string;
  placeholder?: string;
  labelPosition?: "top" | "left";
  contentAlign?: "start" | "end";
  toggleOn?: "hover" | "click";
  by?: string;
  disabled?: boolean;
  className?: string;
  triggerClassName?: string;
};

export const DropdownSwitcher = ({
  items,
  groupByKey,
  selectedItem,
  onSelect,
  trigger,
  placeholder,
  ...props
}: DropdownSwitcherProps) => {
  const { t } = useTranslation();

  const [selectedItemChange, setSelectedItemChange] = useState(0);

  const groupedItems: Record<string, Item[]> = useMemo(() => {
    return groupByKey ? groupBy(items, groupByKey) : {};
  }, [groupByKey, items]);

  return (
    <DropdownSwitcherBase<Item>
      list={items}
      groupedList={groupedItems}
      key={selectedItemChange}
      onSelect={(item) => {
        onSelect(item);
        setSelectedItemChange((prev) => prev + 1);
      }}
      selected={selectedItem}
      getUniqueValue={(item) => item?.id}
      getLabelValue={(item) => item?.name}
      trigger={
        trigger ? (
          trigger
        ) : (
          <DropdownSwitcherBase.Trigger
            placeholder={t((placeholder as any) || "PLACEHOLDER_SELECT")}
            className={props?.triggerClassName}
          />
        )
      }
      sortBy={(itemLeft, itemRight) =>
        itemLeft.name.localeCompare(itemRight.name)
      }
      {...props}
    >
      <>
        {items?.length > 5 ? <DropdownSwitcherBase.Search /> : null}
        <DropdownSwitcherBase.List />
      </>
    </DropdownSwitcherBase>
  );
};

DropdownSwitcher.displayName = "DropdownSwitcher";
