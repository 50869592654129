import {
  forwardRef,
  Fragment,
  type ForwardedRef,
  type ReactElement,
} from "react";
import { ComboboxInput } from "@headlessui/react";
import { TextInput } from "~/components/vendorUI";
import { useDropdownSwitcher } from "../context/DropdownSwitcherContext";
import { css, cva } from "ui/css";

const searchContainerCSS = css({
  padding: "16px",
  borderBottom: "1px solid {colors.gray.4}",
});

const textInputCSS = cva({
  base: {
    borderRadius: "4px",
  },
  variants: {
    error: {
      true: {
        "&:focus": {
          outline: "none",
        },
        borderColor: "red",
      },
    },
  },
});

type SearchRenderProps = ({
  setSearchQuery,
}: {
  setSearchQuery: (query: string) => void;
}) => ReactElement;

type SearchProps = {
  children?: ReactElement | SearchRenderProps;
};

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  ({ children }, ref: ForwardedRef<HTMLInputElement>) => {
    const { setSearchQuery, hasError } = useDropdownSwitcher();

    if (!children) {
      return (
        <div className={searchContainerCSS}>
          <ComboboxInput
            ref={ref}
            as={Fragment}
            displayValue={() => ""}
            onChange={(event) => setSearchQuery(event.target.value)}
          >
            {() => {
              return (
                <TextInput
                  ref={ref}
                  iconEnd="Search"
                  className={textInputCSS({ error: hasError })}
                  data-1p-ignore
                />
              );
            }}
          </ComboboxInput>
        </div>
      );
    }

    return typeof children === "function"
      ? children({ setSearchQuery })
      : children;
  },
);
