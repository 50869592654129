import type React from "react";
import {
  useState,
  useMemo,
  type ReactElement,
  useRef,
  type JSXElementConstructor,
} from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  Label as ComboboxLabel,
} from "@headlessui/react";
import { Popover, Text } from "~/components/vendorUI";
import { Trigger } from "./Trigger";
import { Search } from "./Search";
import { List } from "./List";
import { DropdownSwitcherContextProvider } from "../context/DropdownSwitcherContext";
import { GroupTitle } from "./GroupTitle";
import { css, cva } from "ui/css";

const popoverContentCSS = css({
  zIndex: "dropdown",
  maxWidth: "320px",
  minWidth: "var(--radix-popover-trigger-width)",
});

const boxCSS = cva({
  base: { display: "flex" },
  variants: {
    isLabelTop: {
      true: {
        gap: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      false: {
        gap: "12px",
        flexDirection: "row",
        alignItems: "center",
      },
    },
  },
});

interface DropdownItem {
  id?: string;
  label?: string;
}

interface DropdownSwitcherBaseProps<T> {
  trigger: ReactElement;
  selected?: T | null;
  sortBy?: (itemLeft: T, itemRight: T) => number;
  list?: Array<T>;
  groupedList?: Record<string, T[]>;
  onSelect?: (item: T) => void;
  getLabelValue?: (item: T) => string;
  getUniqueValue?: (item: T) => string | number;
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ((props: {
        open: boolean;
        disabled: boolean;
        activeIndex: number | null;
        activeOption: T | null;
        value: T | null;
      }) => React.ReactElement);
  toggleOn?: "hover" | "click";
  closeOnClick?: boolean;
  label?: string;
  labelPosition?: "top" | "left";
  contentAlign?: "start" | "end";
  disabled?: boolean;
  by?: string;
  triggerClassName?: string;
}

const filterItemByQuery =
  <T extends Record<string, any>>(
    getLabelValue: (item: T) => string,
    searchQuery: string,
  ) =>
  (item: T) => {
    return getLabelValue(item)
      .trim()
      .toLowerCase()
      .includes(searchQuery.trim().toLowerCase());
  };

export const DropdownSwitcherBase = <
  T extends Record<string, any> = DropdownItem,
>({
  trigger,
  selected,
  sortBy,
  list = [],
  groupedList,
  toggleOn = "click",
  closeOnClick = false,
  onSelect = () => {},
  getLabelValue = (item: T) => item?.label,
  getUniqueValue = (item: T) => item?.id,
  children,
  label,
  labelPosition = "top",
  contentAlign,
  disabled,
  by,
  triggerClassName,
}: DropdownSwitcherBaseProps<T>) => {
  if (typeof sortBy === "function") {
    list.sort((itemLeft: T, itemRight: T) => sortBy(itemLeft, itemRight));
  }

  const selectedOptionRef = useRef<HTMLDivElement | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const filteredList = useMemo<Array<T>>(
    () =>
      searchQuery === ""
        ? list
        : list.filter(filterItemByQuery(getLabelValue, searchQuery)),

    [searchQuery, list],
  );
  const groupedFilteredList = useMemo<Record<string, T[]>>(() => {
    if (!groupedList) {
      return {};
    }

    if (searchQuery === "") {
      return groupedList;
    }

    return Object.entries(groupedList).reduce(
      (acc: Record<string, T[]>, [key, items]) => {
        const filteredItems = items.filter(
          filterItemByQuery(getLabelValue, searchQuery),
        );

        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        return filteredItems.length ? { ...acc, [key]: filteredItems } : acc;
      },
      {},
    );
  }, [searchQuery, groupedList]); // evaluate here
  const hasError = useMemo(() => {
    return !!searchQuery && !filteredList.length;
  }, [filteredList, searchQuery]);

  if (!Array.isArray(list) || !list.length) return null;

  if (list.length === 1) {
    return (
      <DropdownSwitcherBase.Trigger
        placeholder={getLabelValue(list[0])}
        className={triggerClassName}
        isArrowHidden
      />
    );
  }

  const isLabelTop = labelPosition === "top";

  return (
    <DropdownSwitcherContextProvider
      value={{
        selected,
        setSearchQuery,
        filteredList,
        disabled,
        groupedFilteredList,
        getUniqueValue,
        getLabelValue,
        hasError,
        selectedOptionRef,
      }}
    >
      <div className={boxCSS({ isLabelTop })}>
        {!!label && (
          <Text color="brand" size="14px" weight="semibold">
            {label}
          </Text>
        )}
        <Popover
          toggleOn={toggleOn}
          onOpenChange={(open) => {
            if (!open) {
              return;
            }

            setTimeout(() => {
              selectedOptionRef.current?.scrollIntoView({ block: "center" });
            }, 0);

            setSearchQuery("");
          }}
        >
          <Popover.Trigger asChild>
            <div>{trigger}</div>
          </Popover.Trigger>
          <Popover.Content
            closeOnClick={closeOnClick}
            avoidCollisions={false}
            sideOffset={toggleOn === "click" ? 4 : 0}
            align={contentAlign}
            className={popoverContentCSS}
          >
            <Combobox
              disabled={disabled}
              value={selected}
              onChange={(item: T) => onSelect(item)}
              by={by}
            >
              {({ ...renderProps }) =>
                typeof children === "function"
                  ? children({ ...renderProps })
                  : children
              }
            </Combobox>
          </Popover.Content>
        </Popover>
      </div>
    </DropdownSwitcherContextProvider>
  );
};

DropdownSwitcherBase.Trigger = Trigger;
DropdownSwitcherBase.Search = Search;
DropdownSwitcherBase.List = List;
DropdownSwitcherBase.ListItem = ComboboxOption;
DropdownSwitcherBase.Label = ComboboxLabel;
DropdownSwitcherBase.Input = ComboboxInput;
DropdownSwitcherBase.GroupTitle = GroupTitle;
