import { Text } from "~/components/vendorUI";
import { css } from "ui/css";

const labelCSS = css({
  padding: "token(spacing.16px) token(spacing.16px) 4px token(spacing.16px)",
  cursor: "default",
});

export const GroupTitle = ({ children }: React.PropsWithChildren) => {
  return (
    <Text
      color="brand"
      size="14px"
      weight="semibold"
      className={labelCSS}
      asChild
    >
      <li>{children}</li>
    </Text>
  );
};
